import axios from "axios";

const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};
const fetchData = async (api) => {
  try {
    const res = await axios.get(api, config);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};

export const postData = async (api, body) => {
  try {
    const res = await axios.post(api, body, config);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    throw Error("Unexpected Error");
  }
};

export const postFormData = async (api, body) => {
  try {
    const res = await axios({
      method: "post",
      url: api,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw Error("Unexpected Error");
  }
};

export default fetchData;
