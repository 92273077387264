import { memo, useEffect, useState } from 'react';
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../../../store";
import { setPopupWithDelete } from '../../../store/reducers/domainAssessmentReducer';
import fetchData, { deleteData, postData } from '../../../hook/http';
import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
const gettableInfo = state => state.domain_assessment.popupWithDelete;
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv()

const DeletePopup = () => {
	const [errorNotification, setErrorNotification] = useState('')
	const [data, setData] = useState(null)
	const table = useTypedSelector(gettableInfo)

	const onSubmit = () => {
		deleteData(`${API_ASSESSMENT}/api/domain-questions/${table?.id}`, {}).then((res) => {
			if (res === '') {
				setErrorNotification('Something went wrong, try again later')
				return
			}
			postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'decrease' }).then(data => { })
			store.dispatch(setPopupWithDelete({ open: false, title: '', id: -1 }))
		}, error => {
			setErrorNotification(error)
		}
		)
	}
	const getData = async () => {
		const res = await fetchData(`${API_RECRUITMENT}/api/workflow/company`)
		const val = res?.rows?.filter((dd) => dd?.domainId === table?.id)
		setData(val)
	}
	useEffect(() => {
		getData()
	}, [])

	return (
		<AuthGuard module='assessment' permission='delete'>
			{data !== null && <div className="popup">
				<div className="popup__step">
					<div className="popup__head">
						<p className="popup__head__headline">
							Delete the Domain Assessment
						</p>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => store.dispatch(setPopupWithDelete({ open: false, title: '', id: -1 }))}
							className="popup__head__close"
						>
							<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round" />
						</svg>
					</div>
					{data?.length === 0 ? <>
						<div className="popup__body">
							<p className="popup__body__text">
								Are your sure, want to delete the <span>{table.title}</span> assessment?
							</p>
						</div>
						<div className="popup__bottom end">
							<button
								onClick={() => store.dispatch(setPopupWithDelete({ open: false, title: '', id: -1 }))}
								className="popup__cancel"
							>
								Cancel
							</button>
							<button
								className="popup__bottom__publish button--filled"
								onClick={() => onSubmit()}
							>
								Delete
							</button>
							{
								errorNotification.length > 0 && (
									<p className="error-message">{errorNotification}</p>
								)
							}
						</div></>
						:
						<>
							<div className="popup__body">
								<p className="popup__body__text">
									<span>{table.title}</span> assessment cannot be deleted. It is assigned in the position workflow. Please remove the references first.
								</p>
							</div>
							<div className="popup__bottom end">
								<button
									className="popup__bottom__publish button--filled"
									onClick={() => store.dispatch(setPopupWithDelete({ open: false, title: '', id: -1 }))}
								>
									Got it
								</button>
								{
									errorNotification.length > 0 && (
										<p className="error-message">{errorNotification}</p>
									)
								}
							</div></>
					}

				</div>
			</div>}
		</AuthGuard>
	)
}

export default memo(DeletePopup)