import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { JobsTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import {
	defaultFiltersJobs, setChangeWorkflowListJobs,
	setCurrentPageJobs,
	setDateSelectedJobs, setEmptySearchJobs, setEmptyTableJobs,
	setLimitJobs,
	setSearchValueJobs,
	setSortByFilterJobs,
	setSortTypeFilterJobs, setStatusFilterJobs, setTableItemsJobs, setTopButtonsJobs, setTotalCountJobs,
} from "../store/reducers/jobsTableReducer";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import JobsFilterItems from "../components/Jobs/JobsFilterItems";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { IJobsTableItem } from "../types/redux/jobs";
import { transformDate } from "../utils/transformDate";
import JobsTBody from "../components/Jobs/TBody/JobsTBody";
import MobileTableJobs from "../components/Jobs/TBody/MobileTableJobs";
import DeletePopupJobs from "../components/Jobs/popups/DeletePopupJobs";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import ApproveJobPopup from "../components/Jobs/popups/ApproveJobPopup";
import RejectPopupJobs from "../components/Jobs/popups/RejectPopupJobs";
import ChangeWorkflowPopup from "../components/Jobs/popups/ChangeWorkflowPopup";
import fetchData from "../hook/http";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv();
const token: string = localStorage.getItem("token");
const config = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token}`,
	},
};


const getFilters = state => state.jobs.filters;
const tableFunc = state => state.jobs;
const getFilterDataFunc = state => state.jobs.filterInfo;
const getLimitFunc = state => state.jobs.pagination.limit;
const FilterItems = state => <JobsFilterItems activeTab={state}/>;
const FilterPopupInnerWrap = (setState) => {
	const filters = useTypedSelector(getFilters);
	const filtersData = useTypedSelector(getFilterDataFunc);
	const limit = useTypedSelector(getLimitFunc);
	return <FilterWrapper setFilterPopup={setState}
	                      resetFunc={resetFunc}
	                      filters={filters}
	                      filterData={filtersData}
	                      submitFunc={setJobsHandler}
	                      limit={limit}
	>
		{FilterItems}
	</FilterWrapper>;
};
const JobsScreen = () => {

	const {checkUserPermission} = useHasPermission()

	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate();

	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);

	const table = useTypedSelector(tableFunc);
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType,
		table.filters.date.from, table.filters.date.to, table.filters.status, table.pagination.limit,
	];
	useLayoutEffect(() => {
		checkUserPermission('job-post','view') && setJobsHandler(table.pagination.currentPage, table.pagination.limit, table.filters);
	}, []);

	useEffect(() => {
		document.addEventListener("visibilitychange", visibilityChangeFunc);
		return () => document.removeEventListener("visibilitychange", visibilityChangeFunc);
	}, [...filterArray, table.pagination.currentPage]);

	const visibilityChangeFunc = () => {
		if (document.visibilityState === "visible") {
			setJobsHandler(table.pagination.currentPage, table.pagination.limit, table.filters);
		}
	};

	useEffect(() => {
		checkUserPermission('job-post','view') && fetchData(`${API_RECRUITMENT}/api/workflow/company-job`).then(res => store.dispatch(setChangeWorkflowListJobs(res.map(item => {
			return {
				label: item.title,
				value: item.id,
			};
		}))));
		document.addEventListener("visibilitychange", visibilityChangeWorkflows);
		return () => document.removeEventListener("visibilitychange", visibilityChangeWorkflows);
	}, []);

	const visibilityChangeWorkflows = () => {
		if (document.visibilityState === "visible") {
			checkUserPermission('job-post','view') && fetchData(`${API_RECRUITMENT}/api/workflow/company-job`).then(res => store.dispatch(setChangeWorkflowListJobs(res.map(item => {
				return {
					label: item.title,
					value: item.id,
				};
			}))));
		}
	};


	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueJobs(value));
		setJobsHandler(1, table.pagination.limit, {...table.filters, searchValue: value});
	}, filterArray);

	const emptyTableCallback = useCallback(() => {
		navigate("/job/create");
	}, []);


	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeFilterJobs, setSortByFilterJobs, setJobsHandler), filterArray);
	useTableClickAndDragScroll(tableRef);
	useClickOutside(orderPopupRef, setOrderPopup);
	return <section>
		{
			table.deletePopupInfo.open && <DeletePopupJobs/>
		}
		{
			table.approvePopup.open && <ApproveJobPopup/>
		}
		{
			table.rejectPopup.open && <RejectPopupJobs/>
		}
		{
			table.changeWorkflow.open && <ChangeWorkflowPopup/>
		}
		<Outlet/>
		<div className="table-screen-top">
			<div className="table-screen-top__wrapper">
				<h2>Jobs</h2>
				<div className="table-screen-top__buttons-group">
					<button className={`table-screen-top__buttons-group__item ${table.topButtons === "all" ? "active" : ""}`}
					        onClick={() => {
						        store.dispatch(setTopButtonsJobs("all"));
						        store.dispatch(setStatusFilterJobs([]));
						        setJobsHandler(1, table.pagination.limit, {...table.filters, status: []});
					        }}>
						All Jobs
					</button>
					<button className={`table-screen-top__buttons-group__item ${table.topButtons === "posted" ? "active" : ""}`}
					        onClick={() => {
						        store.dispatch(setTopButtonsJobs("posted"));
						        store.dispatch(setStatusFilterJobs(["publish"]));
						        setJobsHandler(1, table.pagination.limit, {...table.filters, status: ["publish"]});
					        }}>
						Posted Jobs
					</button>
					<button className={`table-screen-top__buttons-group__item ${table.topButtons === "approval" ? "active" : ""}`}
					        onClick={() => {
						        store.dispatch(setTopButtonsJobs("approval"));
						        store.dispatch(setStatusFilterJobs(["approval"]));
						        setJobsHandler(1, table.pagination.limit, {...table.filters, status: ["approval"]});
					        }}>
						Pending Jobs
					</button>
					<button className={`table-screen-top__buttons-group__item ${table.topButtons === "draft" ? "active" : ""}`}
					        onClick={() => {
						        store.dispatch(setTopButtonsJobs("draft"));
						        store.dispatch(setStatusFilterJobs(["draft"]));
						        setJobsHandler(1, table.pagination.limit, {...table.filters, status: ["draft"]});
					        }}>
						Draft
					</button>
				</div>
			</div>
			<AuthGuard module='job-post' permission='add'>
				<button className="button--filled table-screen-top__button" onClick={() => navigate("/job/create")}>
					Add New
				</button>
			</AuthGuard>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className="manage-team__top__left">
					<AuthGuard module='job-post' permission='view'>
					<TopFilterButton>
						{
							FilterPopupInnerWrap
						}
					</TopFilterButton>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={"Search jobs"}/>
					</AuthGuard>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? "active" : ""}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup}/>
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={"order-popup-mtm"} unmountOnExit mountOnEnter>
							<JobsTableOrder setOrderPopup={setOrderPopup}/>
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptySearch ? <NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc}/>
							: <TableEmpty 
										handler={checkUserPermission('job-post','add') ? emptyTableCallback:()=> navigate("/")} 
										cta={checkUserPermission('job-post','add') ? "Add New":"Go to Dashboard"} 
										title={"Table is empty"}
										desc={checkUserPermission('job-post','add') ? "Jobs table is empty, create your first job":''}/>
					)
					:
					<AuthGuard module='job-post' permission='view'>
							<>
							<table className="table" ref={tableRef} onScroll={(e: any) => {
								if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true);
								else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false);
							}}>
								<thead className="table__thead">
								<td
									className={`table__td sticky ${horizontalScrollState ? "moved" : ""} table__td--thead jobs-table__column__middle`}>
									<TheadItemComponent title={table.fixedTab.displayName} handler={tdOrderCallback} dbName={"title"}/>
								</td>
								{
									table.tabFilter.map((item) => {
										if(item.displayName === 'Actions' && !checkUserPermission('job-post',['edit','delete'])){
											return
										}
										return item.active &&
											<td className={item.id === 2 ? `table__td  table__td--thead jobs-table__column__middle` :
												item.id === 8 ? `table__td  table__td--thead jobs-table__column__small` :
													`table__td  table__td--thead jobs-table__column__default`
											}
													key={item.id}>
												<TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
																						dbName={item.dbName}/>
											</td>;
									})
								}
								</thead>
								<JobsTBody horizontalScrollState={horizontalScrollState} />
							</table>
							<div className="table-mobile">
								{
									table.tableItems.map(item => {
										return <MobileTableJobs key={item.id} item={item}/>;
									})
								}
							</div>
							<PaginationComponent
								limit={table.pagination.limit}
								currentPage={table.pagination.currentPage}
								totalCount={table.pagination.totalCount}
								setCurrentPage={setJobsHandler}
								setLimit={setLimitHandler}
								filters={table.filters}
							/>
						</>
					</AuthGuard>
			}
		</div>
	</section>;
};
export default memo(JobsScreen);


export const setJobsHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageJobs(page));
	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/company/jobs/?limit=${limit}
		&offset=${(page - 1) * limit}
		&search=${encodeURIComponent(filters.searchValue)}
		&dateFrom=${filters.date.from || ""}
		&dateTo=${filters.date.to ? toJSONLocalPlusDay(filters.date.to) : ""}
		${filters.status.map(item => `&status=${item}`).join("")}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`, config).then(res => {
			store.dispatch(setTotalCountJobs(res.data.count));
			const items = transformArrayToTableData(res.data.rows);
			store.dispatch(setTableItemsJobs(items));

			if (filters.status.length === 1) {
				if (filters.status[0] === "publish") store.dispatch(setTopButtonsJobs("posted"));
				else if (filters.status[0] === "approval") store.dispatch(setTopButtonsJobs("approval"));
				else if (filters.status[0] === "draft") store.dispatch(setTopButtonsJobs("draft"));
				else store.dispatch(setTopButtonsJobs("all"));
			} else {
				store.dispatch(setTopButtonsJobs("all"));
			}

			if (items.length === 0) {
				if (filters.searchValue === "" && !filters.date.from && !filters.date.to && filters.status.length === 0) {
					store.dispatch(setEmptyTableJobs(true));
				} else {
					store.dispatch(setEmptySearchJobs(true));
				}
			} else {
				store.dispatch(setEmptySearchJobs(false));
				store.dispatch(setEmptyTableJobs(false));
			}
		});
	};
	getData().then();
};

export function toJSONLocalPlusDay(date: any) {
	let local = new Date(date);
	local.setDate(local.getDate() + 1);

	local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	return local.toJSON().slice(0, 10);
}

const setLimitHandler = (value: any) => {
	store.dispatch(setLimitJobs(value));
};
const transformArrayToTableData = (array: any): Array<IJobsTableItem> => {
	//TODO add missed fields
	return array.map(item => {
		return {
			id: item.id,
			jobTitle: item.title,
			jobLocation: item.locations.length > 0 ? item.locations.map(i => `${i.city} ,${i.state}`).join(' | ') : null,
			salary: `$${item.salaryYearMin} - $${item.salaryYearMax}`,
			postedOn: transformDate(item.createdAt),
			postedBy: `${item.author?.firstname} ${item.author?.lastname}`,
			status: item.status,
			positionWorkflow: {label: item.workflow?.title, value: item.workflow?.id},
			remoteLocations: item.remoteLocation,
			approverId: item.approverId
		};
	});
};
const resetFunc = (pagination) => {
	store.dispatch(setDateSelectedJobs({from: null, to: null}));
	store.dispatch(setSortByFilterJobs("id"));
	store.dispatch(setSortTypeFilterJobs("DESC"));
	store.dispatch(setSearchValueJobs(""));
	store.dispatch(setStatusFilterJobs([]));
	setJobsHandler(1, pagination, defaultFiltersJobs);
};