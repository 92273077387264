import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import { LanguagesSet } from "../../utils/constants";
import fetchData from "../../hook/http";
import { DashboardEmptyDataComponent, useHasPermission, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_ASSESSMENT, API_RECRUITMENT } = getEnv();

const CodingAssessmentsList = ({ position }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const { checkUserPermission } = useHasPermission();
	const { checkCurrentPackageType } = useSubscription();
	const hasAssessmentPackage = (checkCurrentPackageType(['assessment','fullcycle']))

	const assessmentPermission = checkUserPermission('assessment', 'view');
	useEffect(() => {
		async function getInfo() {
			try {
				await fetchData(`${API_ASSESSMENT}/api/coding-assessment/search`).then(data => {
					if (!data?.length) {
						setIsEmptyData(true)
					} else {
						setData(data.slice(0, 20))
					}
					setIsLoading(false)
				})
			} catch (error) {
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}
		if (hasAssessmentPackage && assessmentPermission) {
			getInfo()
		}
	}, [hasAssessmentPackage])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">Coding Assessments</p>
				<div className="dashboard-list__links">
					<Link to="/coding-assessments" className="dashboard-list__head__link">View all</Link>
				</div>
			</div>
			{
				isLoading && <Stack height={"100%"}>
					<Skeleton animation="wave" />
				</Stack>
			}
			{isEmptyData ? <DashboardEmptyDataComponent />
				: <div className="dashboard-list__list">
					{data.length > 0 &&
						data.map(assessment => {
							return <ShowListItem assessment={assessment} key={assessment.id} />
						})
					}
				</div>
			}
		</div>
	);
};

export default memo(CodingAssessmentsList);

const ShowListItem = ({ assessment }) => {

	const language = assessment.languageId ? LanguagesSet.find(lang => lang.id === assessment.languageId)?.name : assessment.questions?.length ? assessment.questions.map(i => LanguagesSet.find(lang => lang.id === i.languageId)?.name||'').join(',') : ''
	return <div className="dashboard-list__item card" >
		<div className="dashboard-list__item__inner">
			<div className="dashboard-list__item__top heading">
				<p className="dashboard-list__item__title">{assessment.name || ""}</p>
				<p className="dashboard-list__item__description">{assessment.description?.length > 45 ? assessment.description?.slice(0, 45) + "..." : assessment.description||''}</p>
			</div>
			<div className="dashboard-list__item__bottom">
				<p className="dashboard-list__item__dot">{assessment.assessmentType === "live-task" ? "Live Coding Assessment" : assessment.assessmentType === "take-home" ?"Take-Home Assessment":""}</p>
				{language &&
					<p className="dashboard-list__item__text gray">{language}</p>
				}
			</div>
		</div>
		{/* <div className="dashboard-list__item__arrow" onClick={()=>navigate("/coding-assessments")}>
		<ArrowRight/>
	</div> */}
	</div>
}

// const ArrowRight = memo(() => {

// 	return (
// 		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
// 		</svg>
// 	);
// });