import { memo, useState } from 'react';
import deleteIc from '../../../../image/icon/delete_image_popup_ic.svg';
import { store, useTypedSelector } from "../../../../store";
import { setDeletePopupMTM } from "../../../../store/reducers/manageTeamMembersReducer";
import { getEnv } from "@urecruits/api";
import { deleteData, postData } from "../../../../hook/http";
import { setUsersHandlerMTM } from "../../../../screen/ManageTeamMembers";
import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
const gettableInfo = state => state.team_members
const DeletePopupMTM = () => {
	const [errorNotification, setErrorNotification] = useState('')
	const { API_RECRUITMENT } = getEnv()

	const table = useTypedSelector(gettableInfo)
	const onSubmit = () => {
		deleteData(`${API_RECRUITMENT}/api/company/delete-member`, {
			userId: table.deletePopupInfo.id,
		}).then((res) => {
			if (res === '') {
				setErrorNotification('Something went wrong, try again later')
				return
			}
			postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'user', type: 'decrease' }).then(data => { })
			store.dispatch(setDeletePopupMTM({ open: false, name: '', id: -1 }))
			setUsersHandlerMTM(1, table.pagination.limit, table.filters)
		}, error => {
			setErrorNotification(error)
		}
		)
	}

	return (
		<AuthGuard module='team-members' permission='delete'>
			<div className="popup">

				<div className="popup__step">
					<div className="popup__head">
						<p className="popup__head__headline">
							Delete Member
						</p>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => store.dispatch(setDeletePopupMTM({ open: false, name: '', id: -1 }))}
							className="popup__head__close"
						>
							<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round" />
						</svg>
					</div>
					<div className="popup__body">
						<img src={deleteIc} alt="" className="popup__body__image" />
						<p className="popup__body__text">
							Are you sure you want to delete this member: <span>{table.deletePopupInfo.name}</span>? This action will delete these
							member from <span>uRecruits</span>.
						</p>
					</div>
					<div className="popup__bottom end">
						<button
							onClick={() => store.dispatch(setDeletePopupMTM({ open: false, name: '', id: -1 }))}
							className="popup__bottom__cancel button--empty"
						>
							Cancel
						</button>
						<button
							className="popup__bottom__publish button--filled"
							onClick={() => onSubmit()}
						>
							Delete
						</button>
						{
							errorNotification.length > 0 && (
								<p className="error-message">{errorNotification}</p>
							)
						}
					</div>
				</div>


			</div>
		</AuthGuard>
	)
}

export default memo(DeletePopupMTM)
