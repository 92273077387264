import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import plusIcon from '../../image/icon/plus_ic.svg';
import clockIc from '../../image/icon/clock_ic.svg';

import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import fetchData from "../../hook/http";
import { AuthGuard, DashboardEmptyDataComponent, ShowToolTipComponent, useHasPermission, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_ASSESSMENT, API_RECRUITMENT } = getEnv();

const DomainAssessmentsList = ({ position }) => {
	const [data, setData] = useState([])
	const [industries, setIndustries] = useState<any>()
	const [isLoading, setIsLoading] = useState(true)
	const [isEmptyData, setIsEmptyData] = useState(false)
	const { checkUserPermission } = useHasPermission();
	const { checkCurrentPackageType } = useSubscription()

	const hasAssessmentPackage = (checkCurrentPackageType(['assessment','fullcycle']))
	const assessmentPermission = checkUserPermission('assessment', 'view');
	useEffect(() => {
		async function getInfo() {
			try {
				const assessments = await fetchData(`${API_ASSESSMENT}/api/domain-questions?limit=20&offset=0`)
				if (assessments?.rows?.length) {
					setData(assessments?.rows);
					await fetchData(`${API_RECRUITMENT}/api/industry/label`).then(data => {
						setIndustries(data.data)
					})
				} else {
					setIsEmptyData(true)
				}
				setIsLoading(false)
			} catch (error) {
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}

		if (hasAssessmentPackage && assessmentPermission) {
			getInfo()
		} 
	}, [assessmentPermission,hasAssessmentPackage])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">Domain Assessments</p>
				<div className="dashboard-list__links">
					<AuthGuard module='assessment' permission='view' >
						<Link to="/assessment/domain-assessment" className="dashboard-list__head__link">View all</Link>
					</AuthGuard>
					<AuthGuard module='assessment' permission='add' >
						<Link to="/manage-domain">
							<ShowToolTipComponent text='Click to create Domain assessment'>
								<img src={plusIcon} alt="Add Domain Assessment Icon" />
							</ShowToolTipComponent>
						</Link>
					</AuthGuard>
				</div>
			</div>
			<AuthGuard module='assessment' permission='view' >
				{
					isLoading && <Stack height={"100%"}>
						<Skeleton animation="wave" />
					</Stack>
				}
				{
					isEmptyData ? <DashboardEmptyDataComponent />
						:
						<div className="dashboard-list__list">
							{data.length > 0 &&
								data.map(assessment => {
									const industry = industries?.find(indu => indu.id === assessment.industryId)
									return <ShowListItem assessment={assessment} key={assessment.id} industry={industry} />
								})
							}
						</div>
				}
			</AuthGuard>
		</div>
	);
};

export default memo(DomainAssessmentsList);

const ShowListItem = ({ assessment, industry }) => {

	const navigate = useNavigate()

	return <div className="dashboard-list__item" >
		<div className="dashboard-list__item__inner">
			<div className="dashboard-list__item__top heading">
				<p className="dashboard-list__item__title" onClick={() => navigate("/assessment/domain-assessment")}>{assessment.name || ""}</p>
			</div>
			<div className="dashboard-list__item__bottom">
				{industry &&
					<p className="dashboard-list__item__dot">{industry.label || ""}</p>
				}
				{
					assessment.duration &&
					<p className="dashboard-list__item__time">
						<img
							src={clockIc}
							alt="clock icon"
						/>
						{assessment.duration}
					</p>
				}
			</div>
		</div>
		{/* <div className="dashboard-list__item__arrow" onClick={() => navigate("/assessment/domain-assessment")}>
			<ArrowRight />
		</div> */}
	</div>
}

// const ArrowRight = memo(() => {

// 	return (
// 		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
// 		</svg>
// 	);
// });